import React from 'react';
import logo from './logo.svg';
import Picky from "react-picky"
import './App.css';

import ReactHighcharts from "react-highcharts";

import "react-picky/dist/picky.css"


class App extends React.Component {
    constructor(props) {
        super(props);
        this.chart = React.createRef();
        this.state = {
            stack_category: [],
            stack_data: [],
            city_data: [],
            category_filter: [],
            state_filter:[],
            scale_filter: [],
        }
    }


    componentDidMount() {
        window.addEventListener("message", (e) => {

            let data = e.data;
            if (data.type !== "bar_chart") {
                return;
            }

            let city_data = data.city_data.map(d => {
                if (d.hasOwnProperty("scale")) {
                    let scale = d.scale;
                    delete d.scale;
                    d.cityScale = scale;
                }
                if (!d.cityScale) {
                    d.cityScale = "None"
                }
                if (d.hasOwnProperty("state")) {
                    let state = d.state;
                    delete d.state;
                    d.cityState = state;
                }
                if (!d.cityState) {
                    d.cityState = "None"
                }
                return d;
            });

            this.setState({
                stack_category: data.stack_category.map(f => f.split("</strong>")[0].split("<strong>")[1]),
                city_data: city_data,
                stack_data: data.stack_data,
                scale_filter: Array.from(new Set(city_data.map(s => s.cityScale))),
                state_filter: Array.from(new Set(city_data.map(s => s.cityState))),
                category_filter: data.stack_category.map(
                    f => f.split("</strong>")[0].split("<strong>")[1]
                ).map((v, i) => ({text: v, index: i})),
            })
        });
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
        console.log(errorInfo);

    }

    render() {
        let config = {
            chart: {
                type: 'bar',
                marginTop: 70
            },
            exporting: {
                enabled: true
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: this.state.stack_category.filter((v, i) => {
                        let cat_fil = this.state.category_filter.find(f => f.index === i)
                        let scale_fil = this.state.scale_filter.find(f => f === this.state.city_data[i].cityScale)
                        let state_fil = this.state.state_filter.find(f => f === this.state.city_data[i].cityState)
                        return cat_fil && scale_fil && state_fil
                    })
            },
            yAxis: {
                min: 0,
                title: {
                    text: null
                }
            },
            legend: {
                itemStyle: {
                    fontSize: '10px',
                    fontFamily: 'Verdana, sans-serif',
                    color: '#A0A0A0'
                },
                reversed: true
            },

            plotOptions: {
                series: {
                    stacking: 'normal',
                    point: {
                        events: {
                            // click: function () {
                            //     // this is the event triggered by stackbar click
                            //     // invoke different drill down method by different parameters
                            //     var majorIndexId = this.series.options.majorIndexId;
                            //     if (paramOptions.isCompare == true) {
                            //         paramOptions.majorIndexId = majorIndexId;
                            //         stackBarDrillDownCompare(paramOptions);
                            //     } else if (paramOptions.isAction == true) {
                            //         paramOptions.majorIndexId = majorIndexId;
                            //         stackBarDrillDownAction(paramOptions);
                            //     } else {
                            //         stackBarDrillDown(majorIndexId, paramOptions.stackChartNode);
                            //     }
                            // }
                        }
                    }
                }
            },
            series: this.state.stack_data.map(d => {
                return {
                    ...d, data: d.data.filter((v, i) => {
                        let cat_fil = this.state.category_filter.find(f => f.index === i)
                        let scale_fil = this.state.scale_filter.find(f => f === this.state.city_data[i].cityScale)
                        let state_fil = this.state.state_filter.find(f => f === this.state.city_data[i].cityState)
                        return cat_fil && scale_fil && state_fil
                    })
                }
            })
        };
        return <div style={{height: "100%", position: "relative"}}>

            <ReactHighcharts domProps={{style: {'height': '100%'}}} config={config} ref={this.chart}></ReactHighcharts>

            <div style={{position: "absolute", top: 5, left: 10, right: 10}}>

                <div style={{display: "flex"}}>
                    <div style={{alignItems: "center", flex:"1 0 0", marginRight:"20px"}}>
                        Cities
                        <Picky
                            onChange={e => this.setState({category_filter: e})}
                            value={this.state.category_filter}
                            options={this.state.stack_category.map((s, i) => ({
                                text: s, index: i,
                            }))}
                            valueKey={"index"}
                            labelKey={"text"}
                            multiple={true}
                            render={({
                                         style,
                                         isSelected,
                                         item,
                                         selectValue,
                                         labelKey,
                                         valueKey,
                                         multiple,
                                     }) => {
                                return (
                                    <li
                                        style={style} // required
                                        className={isSelected ? 'selected' : ''} // required to indicate is selected
                                        key={item[valueKey]} // required
                                        onClick={() => selectValue(item)}
                                    >
                                        {/* required to select item */}
                                        <input type="checkbox" checked={isSelected} readOnly/>
                                        <span style={{fontSize: '10px'}}>{item["text"]}</span>
                                    </li>
                                );
                            }}
                        >

                        </Picky>
                    </div>
                    <div style={{alignItems: "center", flex:"1 0 0", marginRight:"20px"}}>
                        Scale
                        <Picky
                            onChange={e => this.setState({scale_filter: e})}

                            value={this.state.scale_filter}
                            options={Array.from(new Set(this.state.city_data.map(s => s.cityScale)))}

                            multiple={true}
                            render={({
                                         style,
                                         isSelected,
                                         item,
                                         selectValue,
                                         labelKey,
                                         valueKey,
                                         multiple,
                                     }) => {
                                return (
                                    <li
                                        style={style} // required
                                        className={false} // required to indicate is selected
                                        //key={item || "none"} // required

                                        onClick={() => selectValue(item)}
                                    >
                                        {/* required to select item */}
                                        <input type="checkbox" checked={isSelected} readOnly/>
                                        <span style={{fontSize: '10px'}}>{item || "none"}</span>
                                    </li>
                                );
                            }}
                        />
                    </div>

                    <div style={{flex:"1 0 0"}}>
                        <div>
                            State
                        </div>
                        <Picky
                            onChange={e => this.setState({state_filter: e})}

                            value={this.state.state_filter}
                            options={Array.from(new Set(this.state.city_data.map(s => s.cityState)))}

                            multiple={true}
                            render={({
                                         style,
                                         isSelected,
                                         item,
                                         selectValue,
                                         labelKey,
                                         valueKey,
                                         multiple,
                                     }) => {
                                return (
                                    <li
                                        style={style} // required
                                        className={false} // required to indicate is selected
                                        //key={item || "none"} // required

                                        onClick={() => selectValue(item)}
                                    >
                                        {/* required to select item */}
                                        <input type="checkbox" checked={isSelected} readOnly/>
                                        <span style={{fontSize: '10px'}}>{item || "none"}</span>
                                    </li>
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default App;
